<template>
  <div
    class="wrapper"
    :style="{ background: `url(${IMAGES.bg}) no-repeat top center` }"
    v-show="MUSIC_FEED.play_url"
    @click="handleMusic"
  >
    <div class="content">
      <div class="player">
        <img
          :src="IMAGES.player"
          :class="['player-wrapper', isPlaying ? 'player-wrapper--active' : 'player-wrapper--pause']"
          @click="handleMusic"
          alt="player"
        />
        <img
          src="~@/assets/images/seventh-night/control.png"
          alt="control"
          :class="['player-control', isPlaying ? 'player-control--active' : 'player-control--pause']"
        />
      </div>
      <p class="title">{{ MUSIC_FEED.title }}</p>
      <p class="singer">{{ MUSIC_FEED.singer }}</p>
      <audio ref="audio" @canplaythrough="canPlayThrough" @timeupdate="timeUpdate" class="music">
        <source :src="MUSIC_FEED.play_url" type="audio/mp3" />
      </audio>
      <div class="progress">
        <span>{{ currentTime | formatMusicTime }}</span>
        <div class="progress__slider">
          <van-slider
            v-model="progress"
            @drag-start="handleDragStart"
            @change="handleDragEnd"
            bar-height="0.1rem"
            button-size="0.16rem"
            active-color="#ffffff"
            inactive-color="#D8D8D8"
            bar-width="2.34rem"
            min="0"
            max="100"
            step="1"
          />
        </div>
        <span>{{ duration | formatMusicTime }}</span>
      </div>
      <div class="sweet-words">
        <div class="sweet-words-item" v-for="item in sweetWordsList">{{ item }}</div>
      </div>
      <div class="swipe-wrapper">
        <!-- <van-swipe height="22" class="swipe" :autoplay="5000" vertical :show-indicators="false">
          <van-swipe-item v-for="item in swipeMessages" class="swipe_item">
            {{ item }}
          </van-swipe-item>
        </van-swipe> -->
        <div class="swipe">
          <div class="swipe_item">
            <p class="fake_win_price_text">投稿总播放量第一名得<span class="red">52元</span>现金红包</p>
          </div>
        </div>
      </div>
      <div class="share_btn" @click.stop="shareLink">
        <a :href="buttonLink">分享情歌</a>
      </div>
      <IntroModal v-show="showIntro" @close="closeIntro" />
    </div>
  </div>
</template>

<script>
  import API from '@/api';
  import { mapGetters } from 'vuex';
  import { formatTime } from '@/utils/common';
  import Download from '@/utils/download';
  import IntroModal from '@/components/Modal/IntroModal/index.vue';

  export default {
    name: 'index',
    components: { IntroModal },
    props: {},
    data() {
      return {
        isPlaying: false,
        progress: 0, // 播放进度
        currentTime: 0, // 当前播放时长
        duration: 0, // 播放总时长
        showIntro: false,
        buttonLink: 'javascript:void(0);',
        sweetWordsList: [],
        SweetWords: {
          0: ['“你看”', '“一辈子是多久”', '“一辈子就是我爱你那么久”'],
          1: ['“在吗”', '“我一见到你”', '“我就变得不像我自己”'],
          2: ['“好想问”', '“是你上错了车”', '“还是我下错了站”'],
          3: ['“说不清”', '“为什么爱你”', '“但你就是我不爱别人的理由”'],
          4: ['“我为什么爱你”', '“关于这个问题”', '“我想用一生来回答”'],
          5: ['“我不知道会在何时说出我爱你”', '“也许明天”', '“也许永远不会”'],
          6: ['“从前的日色变的慢”', '“车、马、邮件都慢”', '“一生只够爱一人”'],
          7: ['“我喜欢你”', '“第一行是假的”', '“第二行也是假的”'],
          8: ['“我写了三行字”', '“爱你要藏在哪里合适”', '“你又能一看便知”'],
          9: ['“书画琴棋诗酒花”', '“因为你”', '“柴米油盐酱醋茶”'],
        },
        swipeMessages: ['投稿总播放量第一名得52元现金红包', '投稿即得520金币'],
      };
    },
    filters: {
      formatMusicTime(time) {
        return formatTime(time);
      },
    },
    computed: {
      ...mapGetters('activity/seventhNight', ['MUSIC_FEED', 'IMAGES']),
    },
    watch: {
      isPlaying: {
        handler(val) {
          if (!val) {
            this.$refs.audio.pause();
          } else {
            this.$refs.audio.play();
          }
        },
      },
    },
    async asyncData({ store, $http, params }) {
      const { music_feed, background_image } = await $http.post(API.GET_SEVENTH_NIGHT_PAGE_INFO, {
        ...params,
      });

      store.commit('activity/seventhNight/SET_MUSIC_FEED', music_feed);
      store.commit('activity/seventhNight/SET_IMAGES', background_image);
    },
    created() {},
    mounted() {
      if (this.$platform.Android && (!this.$platform.WeiXin || !this.$platform.QQ)) {
        const SCHEME =
          this.$route.query.app_name && this.$route.query.app_name !== 'undefined'
            ? this.$route.query.app_name
            : 'kuaiyin';
        let web_url;

        if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
          web_url = `http://h5.rd.kaixinyf.cn/qixi-activity?position=landing`;
        } else if (process.env.NODE_ENV === 'pub') {
          web_url = `https://h5.pub.kaixinyf.cn/qixi-activity?position=landing`;
        } else if (process.env.NODE_ENV === 'production') {
          web_url = `https://h5.kaixinyf.cn/qixi-activity?position=landing`;
        }

        this.buttonLink = `${SCHEME}://web?web_url=${web_url}`;
      } else {
        this.buttonLink = 'javascript:void(0);';
      }
      const randomNumber = parseInt(Math.random() * 10);
      this.sweetWordsList = this.SweetWords[randomNumber];
      console.log(`randomNumber`, randomNumber);
      console.log(`sweetWordsList`, this.sweetWordsList);
    },
    methods: {
      canPlayThrough(e) {
        this.duration = e.target.duration;
      },
      timeUpdate(e) {
        this.isPlaying = !e.target.paused;
        this.currentTime = e.target.currentTime;
        this.progress = (this.currentTime / this.duration) * 100;
      },
      handleDragStart() {
        this.$refs.audio.pause();
      },
      handleDragEnd(value) {
        let audio = this.$refs.audio;

        audio.currentTime = (value / 100) * audio.duration;
        audio.play();
      },
      handleMusic() {
        this.isPlaying = !this.isPlaying;
      },
      shareLink() {
        console.log(`shareLink`);
        this.$track({
          page_title: '2023七夕落地页',
          element_name: '分享情话',
        });
        try {
          let jump = () => {
            let url;

            if (this.$platform.Android && (this.$platform.WeiXin || this.$platform.QQ)) {
              // url = Download.downLoad('yyb');
              this.showIntro = true;
              document.body.style.overflow = 'hidden';
              return;
            } else if (this.$platform.Android && (!this.$platform.WeiXin || !this.$platform.QQ)) {
              url = Download.downLoad('yyb');
              setTimeout(() => {
                window.location.href = url;
              }, 3000);
              return;
            } else if (this.$platform.iOS) {
              url = Download.downLoad('yyb');
            } else {
              url = Download.downLoad('yyb');
            }
            window.location.href = url;
          };
          jump();
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },
      closeIntro() {
        this.showIntro = false;
        document.body.style.overflow = 'auto';
      },
    },
  };
</script>

<style lang="less" scoped>
  .wrapper {
    overflow: hidden;
    position: relative;
    height: 100vh;
    background-size: cover !important;
    .content {
      overflow: hidden;
      width: 100%;
      height: 100vh;
      background: rgba(174, 174, 174, 0.3);
      border-radius: 0.02rem;
      backdrop-filter: blur(3px);
    }
    .player {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &-wrapper {
        position: absolute;
        top: -3.25rem;
        width: 5.9rem;
        height: 5.9rem;
        border-radius: 50%;
        &--active {
          animation: music 5s infinite linear;
        }
        &--pause {
          animation: music 5s infinite linear;
          animation-play-state: paused;
        }
      }
      &-control {
        position: absolute;
        top: -0.1rem;
        right: 1.05rem;
        width: 1.32rem;
        height: 1.78rem;
        color: #fff;
        &--active {
          animation: controlStart 0.68s ease 1;
          animation-fill-mode: forwards;
        }
        &--pause {
          animation: controlPause 0.68s ease 1;
          animation-fill-mode: forwards;
        }
      }
    }
    .title {
      position: absolute;
      top: 2.77rem;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 0.36rem;
      font-weight: 600;
      color: #ffffff;
    }
    .singer {
      position: absolute;
      top: 3.33rem;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 0.3rem;
      color: #ffffff;
    }
    .progress {
      position: absolute;
      top: 3.88rem;
      left: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        width: 0.86rem;
        height: 0.44rem;
        font-size: 0.32rem;
        font-weight: 600;
        color: #ffffff;
      }
      &__slider {
        display: block;
        margin: 0 0.22rem;
        width: 3.74rem;
      }
    }
  }

  .sweet-words {
    margin: 5rem 0.7rem 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &-item {
      position: relative;
      margin-bottom: 0.28rem;
      padding: 0.12rem 0.18rem;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 0.14rem;
      font-size: 0.32rem;
      font-weight: 600;
      color: #ffffff;
      &::before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        top: 0.24rem;
        left: -0.16rem;
        border-top: 0.12rem solid transparent;
        border-bottom: 0.12rem solid transparent;
        border-right: 0.18rem solid rgba(0, 0, 0, 0.4);
      }
    }
  }

  .swipe-wrapper {
    height: 0.44rem;
    border-radius: 0.22rem;
    margin: 2rem auto 0.24rem;
    text-align: center;
    line-height: 0.44rem;
    overflow: hidden;
    .swipe {
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      .swipe_item {
        width: 100%;
        height: 0.44rem;
        backface-visibility: hidden;
        .fake_win_price_text {
          height: 0.44rem;
          line-height: 0.4rem;
          font-size: 0.26rem;
          color: #ffffff;
          backface-visibility: hidden;
        }
      }
    }
  }
  .red {
    color: red;
  }
  .share_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 5.9rem;
    height: 0.9rem;
    background: #3377ff;
    border-radius: 0.46rem;
    font-size: 0.36rem;
    font-weight: 600;
    a {
      color: #ffffff;
    }
  }

  .van-slider {
    display: block;
  }

  @keyframes music {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes controlStart {
    from {
      transform: rotate(-40deg);
      transform-origin: top right;
    }
    to {
      transform: rotate(10deg);
      transform-origin: top right;
    }
  }
  @keyframes controlPause {
    from {
      transform: rotate(10deg);
      transform-origin: top right;
    }
    to {
      transform: rotate(-40deg);
      transform-origin: top right;
    }
  }
</style>
